$theme-colors: (
  'primary': #005fbc,
);
// Override primary button's text color.
.btn.btn-primary {
  color: #fff;
}

// Project Switcher css
.modal-dialog.ceres--project-switcher,
.modal-dialog.ceres--advance-search-filter,
.modal-dialog.ceres--subscription-list {
  min-width: 70%;
  .modal-content {
    border: none;
    border-radius: 0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }
  &.ceres--advance-search-filter,
  &.ceres--subscription-list {
    min-width: inherit;
  }
}
.coust-backdrop {
  z-index: 10001 !important;
}
.ceres-map-logo {
  position: absolute;
  bottom: 0 !important;
  width: 100px;
  height: auto;
}

.ceres-map-navigation-main {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
}
.JobLayersPopup {
  margin-top: 40px;
}

@import 'node_modules/bootstrap/scss/bootstrap';
